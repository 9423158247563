import { CREATE_ACCOUNT_ERRORS, CREATE_ACTIONS } from './actions';
import { isUAE } from 'utils/env';
const {
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
  SET_USER_PROP,
} = CREATE_ACTIONS;

const {
  EMAIL_EXISTS,
  EMAIL_EXISTS_AND_INVALID_PHONE,
  INVALID_PHONE
} = CREATE_ACCOUNT_ERRORS;


interface ReducerState {
  loading: boolean,
  emailExistsError: boolean,
  invalidPhoneError: boolean,
  user: {
    name: string,
    email: string,
    phone_number: string,
    password: string,
    intlCode: string,
  },
}

interface ReducerAction {
  type: string,
  [key: string]: any
}

export const reducerInitialState: ReducerState = {
  loading: false,
  emailExistsError: false,
  invalidPhoneError: false,
  user: {
    name: '',
    email: '',
    phone_number: '',
    password: '',
    intlCode: isUAE?'+971':'+44',
  }
};


export default function reducer(state: ReducerState, action: ReducerAction) {
  const {payload} = action;
  switch (action.type) {
    case SET_USER_PROP:
      return {
        ...state,
        user: {
          ...state.user,
          [action.prop]: action.value
        }
      };
    case CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        emailExistsError: false,
        invalidPhoneError: false
      };

    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        emailExistsError: false,
        invalidPhoneError: false
      };

    case CREATE_ACCOUNT_ERROR:
      let emailExistsError = false;
      let invalidPhoneError = false;

      if (payload === EMAIL_EXISTS) {
        emailExistsError = true;
      }

      if (payload === INVALID_PHONE) {
        invalidPhoneError = true;
      }

      if (payload === EMAIL_EXISTS_AND_INVALID_PHONE) {
        emailExistsError = true;
        invalidPhoneError = true;
      }

      return {
        ...state,
        loading: false,
        emailExistsError,
        invalidPhoneError
      };

    default:
      return state;
  }
}
