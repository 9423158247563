import React, { useContext, useReducer } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { helperTextForPassword, isValidEmail, isValidName, isValidNewPassword, isValidPhone, sanitizePhoneNumber } from 'utils';
import css from './CreateStudentAccountForm.module.scss';
import { Box } from 'rebass';
import reducer, {reducerInitialState} from './reducer';
import {CREATE_ACTIONS, requestCreateTutorAccount} from './actions';
import IntlCodes from 'utils/intlCodes.json';
import { Link } from 'react-router-dom';
import { KEY_CONTENT_ROUTES } from 'routing';
import { User } from 'types/Users';
import { History } from 'history';
import cx from 'classnames';
import { AUTH_EXPIRY, AUTH_TOKEN, saveItem, USER_PROFILE } from '../../utils/storage';
import AuthContext from 'context/AuthContext';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';

interface CreateStudentAccountFormProps {
  onCreateSuccess: (user: User, token: string) => any,
  onCreateError?: () => void,
  history: History,
  simplified?: boolean | undefined,
  hideFooter?: boolean,
  hideTerms?: boolean,
  submitBtnClassName?: string,
}

export default function CreateStudentAccountForm(props: CreateStudentAccountFormProps) {
  const {
    history,
    onCreateSuccess,
    onCreateError = () => {},
    simplified = false,
    hideFooter = false,
    hideTerms = false,
    submitBtnClassName = ''
  } = props;
  const { TERMS, PRIVACY_POLICY, LOGIN } = KEY_CONTENT_ROUTES;
  const [state, dispatch] = useReducer(reducer, reducerInitialState);

  const setName = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'name', value});
  };
  const setEmail = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'email', value});
  };
  const setPhoneNumber = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'phone_number', value});
  };
  const setPassword = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'password', value});
  };
  const setIntlCode = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'intlCode', value});
  };

  const {user, loading, emailExistsError, invalidPhoneError} = state;
  const {name, email, phone_number, password, intlCode} = user;

  const formIsValid = () =>
    (simplified || isValidPhone(phone_number)) &&
    isValidEmail(email) &&
    isValidName(name) &&
    isValidNewPassword(password);

  const auth = useContext(AuthContext);

  const onSuccess = (user: User, token: string) => {
    auth.setUser(user);
    auth.setAuthToken(token);
    saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY);
    saveItem(AUTH_TOKEN, token, AUTH_EXPIRY);
    onCreateSuccess(user, token);
  };

  const submitForm = () => {
    if (formIsValid()) {
      const [first_name, ...last_name] = name.split(' ');
      const data: {[key: string]: any} = {
        first_name,
        last_name: last_name.join(' '),
        password,
        email,
        agreed_to_terms: true,
      };
      if (!simplified) {
        data.phone_number = `${intlCode}${phone_number}`;
      }
      requestCreateTutorAccount(data, dispatch, onSuccess, onCreateError);
    }
  };

  const validName = isValidName(name);
  const showErrorForName = name.length > 2 && !validName;

  const validEmail = isValidEmail(email);
  const showErrorForEmail = email.length > 5 && !validEmail;

  const validPhone = isValidPhone(phone_number);
  const showErrorForPhone = phone_number.length > 7 && !validPhone;

  const { t } = useTranslation()

  return (
    <Form layout="vertical" className="ant-next ant-form-medium">
      <Form.Item
        hasFeedback={validName}
        validateStatus={showErrorForName ? 'error' : 'success'}
        help={
          showErrorForName ? `${t('Please enter a first and last name')}` : ''
        }
      >
        <Input
          value={name}
          size="large"
          onChange={e => setName(e.target.value)}
          disabled={loading}
          autoComplete="name"
          placeholder={t("First and Last name")}
        />
      </Form.Item>
      <Form.Item
        hasFeedback={validEmail}
        validateStatus={emailExistsError || showErrorForEmail ? 'error' : 'success'}
        help={
          emailExistsError
            ? `${t('That email address is already in use')}`
            : showErrorForEmail
            ? `${t('Please enter a valid email address')}`
            : ''
        }
      >
        <Input
          value={email}
          size="large"
          onChange={e => setEmail(e.target.value)}
          disabled={loading}
          autoComplete="email"
          placeholder={t("Email")}
        />
      </Form.Item>
      {!simplified && (
        <Form.Item
          hasFeedback={validPhone || invalidPhoneError || showErrorForPhone}
          validateStatus={
            showErrorForPhone || invalidPhoneError
              ? 'error'
              : 'success'
          }
          help={
            invalidPhoneError
              ? `${t('Please check that you have entered a valid international code and phone number without any spaces')}`
              : showErrorForPhone
              ? `${t('Please enter a valid phone number without any spaces')}`
              : ''
          }
        >
          <Input
            value={phone_number}
            addonBefore={(
              <Select
                className={css.phoneNumberSelector}
                value={intlCode}
                onChange={(option: string) => setIntlCode(option)}
              >
                {IntlCodes.countries.map((country, idx) => (
                  <Select.Option key={idx} value={country.code} className={css.option}>
                    <span className={css.mobile}>{country.short_name}</span>
                    <span className={css.desktop}>{country.name}</span> ({country.code})
                  </Select.Option>
                ))}
              </Select>
            )}
            size="large"
            onChange={e => setPhoneNumber(sanitizePhoneNumber(e.target.value))}
            disabled={loading}
            autoComplete="phone"
            placeholder={t("Telephone")}
          />
        </Form.Item>
      )}
      <Form.Item
        hasFeedback={password.length >= 8}
        validateStatus={
          password.length >= 8 && !isValidNewPassword(password)
            ? 'error'
            : 'success'
        }
        help={password.length > 3 ? `${helperTextForPassword(password)}` : ''}
      >
        <Input
          value={password}
          size="large"
          onChange={e => setPassword(e.target.value)}
          type="password"
          disabled={loading}
          autoComplete="new-password"
          placeholder={t("Password")}
        />
      </Form.Item>
      {!isUAE&&!hideTerms && <Box mb={4}>
        {t('By signing up, you are agreeing to Tutor House’s')}
        &nbsp;
        <Link to={TERMS} target="_blank" className={css.termsLink}>
          {t('Terms and Conditions')}
        </Link>
        &nbsp;
        {t('and')}
        &nbsp;
        <Link to={PRIVACY_POLICY} target="_blank" className={css.termsLink}>
          {t('Privacy Policy')}
        </Link>
        .
      </Box>}
      <Box
        mt={0}
        mb={5}
      >
        <Button
          className={cx({
            'ant-submit-btn': true,
            [css.submitFormBtn]: true,
            'btn-primary': true,
            [submitBtnClassName]: true
          })}
          disabled={!formIsValid()}
          loading={loading}
          onClick={submitForm}
        >
          {t('Create Account')}
        </Button>
      </Box>
      {!hideFooter && (
        <>
          <span className={css.goLoginText}>
            {t('Already have an account?')}&nbsp;
          </span>
          <button
            className={css.goLoginBtn}
            onClick={() => history.push(LOGIN)}
          >
            {t('Log In')}
          </button>
        </>
      )}
    </Form>
  );
}
