import React, { useState, Suspense, lazy, useEffect } from 'react';
import { Spin } from 'antd';
import './declares.d';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { PostJobPage, BrowseTutorPage } from 'pages/FindTutorFlow';
import { KEY_CONTENT_ROUTES, AUTHENTICATED_ROUTES } from 'routing';
import AppLayout from 'layouts/AppLayout';
import PrivateRoute from 'components/PrivateRoute';
import { AuthProvider } from 'context/AuthContext';
import { getItem, USER_PROFILE, AUTH_TOKEN, removeItem } from 'utils/storage';
import { User } from 'types';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';
import { updateTimezone } from 'components/Timezone/timezone';
const Page404 = lazy(() => import('./pages/404'));
const Loading = lazy(() => import('./pages/Loading'));
const Homepage = lazy(() => import('./pages/Homepage'));
const OnboardingFlow = lazy(() => import('./pages/OnboardingFlow'));
const SearchTutors = lazy(() => import('./pages/SearchTutors'));
const SearchResults = lazy(() => import('./pages/SearchResults'));
const JobPostFlow = lazy(() => import('./pages/JobPostFlow'));
const CustomerJobs = lazy(() => import('./pages/CustomerJobs'));
const GetStartedFlow = lazy(() => import('./pages/GetStartedFlow'));

const Homeschooling = lazy(() => import('./pages/Homeschooling'));
const Retakes = lazy(() => import('./pages/Retakes'));
const RevisionCourses = lazy(() => import('./pages/RevisionCourses'));
const Contact = lazy(() => import('./pages/Contact'));
const ContentPage = lazy(() => import('./pages/ContentPage'));
const TutorCreateAccount = lazy(() => import('./pages/TutorCreateAccount'));
const TutorDashboard = lazy(() => import('./pages/TutorDashboard'));
const Messages = lazy(() => import('./pages/Messaging'));

const TutorProfile = lazy(() => import('./pages/TutorProfile'));
const SerialCourse= lazy(() => import('./pages/TutorProfile/pages/TurorProfile'));
const TutorPage = lazy(() => import('./pages/TutorProfile/pages/TurorProfile'));
const TutorApplicationSuccess = lazy(() => import('./pages/TutorJobApplicationSuccess'));
const UserEmailVerification = lazy(() => import('./pages/UserEmailVerification'));
const CustomerDashboard = lazy(() => import('./pages/CustomerDashboard'));
const BlogHome = lazy(() => import('./pages/BlogHome'));
const BlogPage = lazy(() => import('./pages/BlogPage'));
const Bookings = lazy(() => import('./pages/Bookings'));
const Login = lazy(() => import('./pages/Login'));
const SelectRole = lazy(() => import('./pages/SelectRole/SelectRole'));

const Signup = lazy(() => import('./pages/Signup'));
const Settings = lazy(() => import('./pages/Settings'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ConfirmNewPassword = lazy(() => import('./pages/ConfirmNewPassword'));
const LogoutPage = lazy(() => import('./pages/Logout'));
const Premium = lazy(() => import('./pages/Premium'));

const AuthCustomerToken = lazy(() => import('./pages/AuthCustomerToken'));
const TutorJobs = lazy(() => import('./pages/TutorJobs'));
const Leaderboard = lazy(() => import('./pages/Leaderboard'));
const TutorJobPostDetails = lazy(() => import('./pages/TutorJobPostDetails'));
const TutorStripeAuthorisation = lazy(() => import('./pages/TutorStripeAuthorisation'));
const StudentCreateAccount = lazy(() => import('./pages/StudentCreateAccount/StudentCreateAccount'));
const FindTutorSalesPage = lazy(() => import('./pages/FindTutorSalesPage'));
const CoronaVirus = lazy(() => import('./pages/CoronaVirus'));

const InternationalStudents = lazy(() => import('./pages/InternationalStudents'));
const TeachWithUs = lazy(() => import('./pages/TeachWithUs'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const PrivateTutoringPermit = lazy(() => import('./pages/PrivateTutoringPermit'));
const OurTeam = lazy(() => import('./pages/OurTeam'));
const OnlineCourses = lazy(() => import('./pages/OnlineCourses'));
const OnlineRevisionCourses = lazy(() => import('./pages/OnlineRevisionCourses'));
const OnlineClassesKs2Ks3 = lazy(() => import('./pages/OnlineClassesKs2Ks3'));

const OnlineCoursesLanguages = lazy(() => import('./pages/OnlineCoursesLanguages'));
const LearnOnlineLanguage = lazy(() => import('./pages/LearnOnlineLanguage'));
// const ALevelCourses = lazy(() => import('./pages/ALevelCourses'));
const Profile = lazy(() => import('./pages/Profile'));
const CustomerReference = lazy(() => import('./pages/CustomerReference'));
const CustomerFeedback = lazy(() => import('./pages/CustomerFeedback'));
const Booking = lazy(() => import('./pages/Booking'));
const BookingSuccessful = lazy(() => import('./pages/BookingSuccessful'));
const CustomerProfile = lazy(() => import('./pages/CustomerProfile'));
const HowItWorks = lazy(() => import('./pages/HowItWorks'));
const Pricing = lazy(() => import('./pages/Pricing'));
const PricingUK = lazy(() => import('./pages/PricingUK'));
const Reviews = lazy(() => import('./pages/Reviews'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));

const App: React.FC = () => {
  const {
    ABOUT,
    PRIVATE_TUTORING_PERMIT,
    HOME,
    LOADING,
    SEARCH,
    SEARCH_RESULTS,
    TUTOR_CREATE_ACCOUNT,
    STUDENT_CREATE_ACCOUNT,
    JOB_POST,
    GET_STARTED,
    RETAKES,
    REVISION_COURSES,
    HOMESCHOOLING,
    CONTACT,
    BLOG,
    TUTOR_OR_CUSTOMER_LOGIN,
    FORGOT_PASSWORD,
    CONFIRM_NEW_PASSWORD,
    LOGOUT,
    REGISTER_ROLE,
    FIND_TUTOR,
    BROWSE_TUTOR,
    POST_JOB,
    PREMIUM,
    CORONA_VIRUS,
    INTERNATIONAL_STUDENTS,
    HOW_IT_WORKS,
    TEACH_WITH_US,
    OUR_TEAM,
    ONLINE_COURSES,
    ONLINE_REVISION_COURSES,
    ONLINE_CLASSES_KS2_KS3,
    JOBS,
    ONLINE_COURSES_LANGUAGES,
    LEARN_ONLINE_LANGUAGE,
    // A_LEVEL_COURSES,
    CUSTOMER_FEEDBACK,
    CUSTOMER_REFERENCE,
    LEADERBOARD,
    PRICING,
    REVIEWS,
    PRIVACY_POLICY,
  } = KEY_CONTENT_ROUTES;

  const {
    TUTOR_DASHBOARD,
    CUSTOMER_DASHBOARD,
    MESSAGES,
    BOOKINGS,
    CUSTOMER_JOBS,
    TUTOR_STRIPE_AUTHORISATION,
    PROFILE,
    BOOKING,
    BOOKING_SUCCESSFUL,
    CUSTOMER_PROFILE,
  } = AUTHENTICATED_ROUTES;

  const userJson = getItem(USER_PROFILE);
  const [ user, setUser ] = useState(
    userJson ? (JSON.parse(userJson) as User) : null,
  );
  const [ authToken, setAuthToken ] = useState(getItem(AUTH_TOKEN));
  const [isSignupModalVisible, setSignupModalVisibility] = useState(false);

  const logout = () => {
    setUser(null);
    setAuthToken(null);
    removeItem(USER_PROFILE);
    removeItem(AUTH_TOKEN);
  };
  const authCtx = {
    user,
    authToken,
    setUser,
    setAuthToken,
    logout,
    isSignupModalVisible,
    setSignupModalVisibility,
  };

  const { t } = useTranslation()

  useEffect(() => {
    updateTimezone()
  }, [])

  return (
    <AuthProvider value={ authCtx }>
      <Router>
        <AppLayout>
          <Suspense fallback={ <Spin tip={t("Loading")}/> }>
            <Switch>
              <Route exact path="/linkedin-callback" component={ LinkedInPopUp }/>
              <Route strict path={ FIND_TUTOR } component={ FindTutorSalesPage }/>
              <Route strict path={ POST_JOB } component={ PostJobPage }/>
              <Route strict path={ BROWSE_TUTOR } component={ BrowseTutorPage }/>

              <Route exact path={ HOME } component={ Homepage}/>
              <Route exact path={ LOADING } component={ Loading }/>

              <Route exact path={ TUTOR_OR_CUSTOMER_LOGIN } component={ Login }/>
              <Route exact path={ REGISTER_ROLE } component={ SelectRole }/>
              <Route
                exact
                path={ `${ CONFIRM_NEW_PASSWORD }/:token` }
                component={ ConfirmNewPassword }
              />

              <Route exact path={ FORGOT_PASSWORD } component={ ForgotPassword }/>

              <PrivateRoute exact path={ LOGOUT } component={ LogoutPage }/>

              <PrivateRoute
                exact
                tutor
                path={ TUTOR_DASHBOARD }
                component={ TutorDashboard }
              />

              <PrivateRoute exact tutor path={ MESSAGES } component={ Messages }/>

              {/* e.g. /messages/1234/authorisation_success is used when tutor returns from registering Stripe details on Messages page */ }
              <PrivateRoute
                path={ `${ MESSAGES }/:chatId/:optional_state_for_stripe_redirect` }
                component={ Messages }
              />

              <PrivateRoute path={ `${ MESSAGES }/:chatId/` } component={ Messages }/>

              <PrivateRoute
                exact
                tutor
                path={ TUTOR_STRIPE_AUTHORISATION }
                component={ TutorStripeAuthorisation }
              />

              <PrivateRoute
                exact
                tutor
                path={ '/settings/' }
                component={ Settings }
              />

              <PrivateRoute
                exact
                student
                path={ CUSTOMER_DASHBOARD }
                component={ CustomerDashboard }
              />

              <PrivateRoute
                exact
                student
                path={ CUSTOMER_PROFILE }
                component={ CustomerProfile }
              />

              <PrivateRoute
                strict
                path={ PROFILE }
                component={ Profile }
              />

              <PrivateRoute
                strict
                path={ BOOKING }
                component={ Booking }
              />

              <PrivateRoute
                strict
                path={ BOOKING_SUCCESSFUL }
                component={ BookingSuccessful }
              />

              <PrivateRoute exact student path={ BOOKINGS } component={ Bookings }/>

              <PrivateRoute
                path={ `${ BOOKINGS }/:bookingId/` }
                component={ Bookings }
              />

              <PrivateRoute
                path={ `/tutor/password/verify_email/:token` }
                component={ UserEmailVerification }
              />

              <PrivateRoute
                path={ `/verify_email/:token` }
                component={ UserEmailVerification }
              />

              <Route path={ TUTOR_CREATE_ACCOUNT } component={ TutorCreateAccount }/>
              <Route path={ STUDENT_CREATE_ACCOUNT } component={ StudentCreateAccount }/>
              <Route exact path={ '/tutor/:tutorId' } component={ TutorPage }/>
              <Route exact path={ '/tutor/copy/:tutorId' } component={ TutorPage }/>
              <Route
                exact
                path="/jobs/:jobPostId/"
                component={ TutorJobPostDetails }
              />
              <PrivateRoute
                exact
                path={ '/jobs/:jobPostId/apply/' }
                component={ TutorApplicationSuccess }
              />
              <Route

                path={ '/tutor/:subjectId/:levelId/:tutorId' }
                component={ TutorProfile }
              />
              <Route
                path={ '/serialCourse' }
                component={ SerialCourse }
              />
              <Route path={ SEARCH } component={ OnboardingFlow }/>
              <Route path={ SEARCH_RESULTS } component={SearchResults} exact/>  
              <PrivateRoute path={ JOB_POST } component={ JobPostFlow }/>
              <PrivateRoute
                path={ `${ CUSTOMER_JOBS }/:jobPostId` }
                component={ CustomerJobs }
                exact
              />
              <PrivateRoute path={ CUSTOMER_JOBS } exact component={ CustomerJobs }/>
              <Route path={ PREMIUM } exact component={ Premium }/>
              <Route path={ CORONA_VIRUS } exact component={ CoronaVirus }/>
              <Route path={ INTERNATIONAL_STUDENTS } exact component={ InternationalStudents }/>

              <Route path={ HOW_IT_WORKS } exact component={ HowItWorks }/>
              <Route path={ PRICING } exact component={ isUAE?Pricing:PricingUK }/>
              <Route path={ TEACH_WITH_US } exact component={ TeachWithUs }/>
              <Route path={ ABOUT } exact component={ AboutUs }/>
              <Route path={ PRIVATE_TUTORING_PERMIT } exact component={ PrivateTutoringPermit }/>
              <Route path={ OUR_TEAM } exact component={ OurTeam }/>
              <Route path={ ONLINE_COURSES } exact component={ OnlineCourses }/>
              <Route path={ ONLINE_REVISION_COURSES } exact component={ OnlineRevisionCourses }/>
              <Route path={ ONLINE_CLASSES_KS2_KS3 } exact component={ OnlineClassesKs2Ks3 }/>
              <Route path={ ONLINE_COURSES_LANGUAGES } exact component={ OnlineCoursesLanguages }/>
              <Route path={ LEARN_ONLINE_LANGUAGE } exact component={ LearnOnlineLanguage }/>
              {/*<Route path={ A_LEVEL_COURSES } strict component={ ALevelCourses }/>*/}
              <Route path={ CUSTOMER_FEEDBACK } exact component={ CustomerFeedback }/>
              <Route path={ CUSTOMER_REFERENCE } exact component={ CustomerReference }/>
              <Route path={ REVIEWS } exact component={ Reviews }/>

              <Route
                path={ `${ GET_STARTED }/:tutorId/:subjectSlug/:locationSlug?/` }
                component={ GetStartedFlow }
              />
              <Route
                path="/users/login_as_user/:token"
                component={ AuthCustomerToken }
                exact
              />
              <Route
                path={ PRIVACY_POLICY }
                component={ PrivacyPolicy }
              />
              <Route path={ JOBS } component={ TutorJobs } exact/>
              <Route path={ LEADERBOARD } component={ Leaderboard } exact/>
              <Route exact path="/signup" component={ Signup }/>
              <Route exact path={ RETAKES } component={ Retakes }/>
              <Route exact path={ HOMESCHOOLING } component={ Homeschooling }/>
              <Route exact path={ REVISION_COURSES } component={ RevisionCourses }/>
              <Route exact path={ CONTACT } component={ Contact }/>
              <Route exact path={ BLOG } component={ BlogHome }/>
              <Route path={ `${ BLOG }/:slug` } component={ BlogPage }/>
              <Route path="/:slug" component={ ContentPage }/>

              <Route component={ () => <Page404/> }/>
            </Switch>
          </Suspense>
        </AppLayout>
      </Router>
    </AuthProvider>
  );
};

export default App;
