import React, { useState, useReducer, useContext } from 'react';
import { Form, Input, Button } from 'antd';
import { Text } from 'rebass';
import { isValidEmail } from 'utils';
import { requestLogin, LoginDispatch } from './actions';
import reducer, { LoginState } from './reducer';
import { User } from 'types/Users';
import { AUTH_EXPIRY, AUTH_TOKEN, saveItem, USER_PROFILE } from 'utils/storage';
import AuthContext from 'context/AuthContext';
import css from './LoginForm.module.scss';
import { LoginRegisterSuccessCb } from 'utils/auth';
import { KEY_CONTENT_ROUTES } from 'routing';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';

const { Item } = Form;

interface LoginProps {
  switchToCustomerOnboarding?: React.MouseEventHandler<HTMLElement>;
  switchToTutorOnboarding?: React.MouseEventHandler<HTMLElement>;
  switchToCreateStudent?: () => void;
  switchToCreateTutor?: () => void;
  switchToForgotPass: any;
  onSuccess: LoginRegisterSuccessCb;
  onError: Function;
}

export default function LoginForm({
  switchToCreateStudent,
  switchToCreateTutor,
  switchToForgotPass,
  onSuccess,
  onError
}: LoginProps) {
  const auth = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [{ loading, incorrectEmailOrPasswordError }, dispatch] = useReducer(
    reducer,
    {
      loading: false,
      incorrectEmailOrPasswordError: false
    }
  ) as [LoginState, LoginDispatch];

  const formIsValid = () =>
    isValidEmail(email)
  const submitForm = () => {
    requestLogin(
      { username: email, password },
      dispatch,
      onSuccessfulLogin,
      onError
    );
  };

  const storeCredentials = (user: User, token: string): void => {
    auth.setUser(user);
    auth.setAuthToken(token);
    saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY);
    saveItem(AUTH_TOKEN, token, AUTH_EXPIRY);
  };

  const onSuccessfulLogin: LoginRegisterSuccessCb = (user: User, token: string): void => {
    storeCredentials(user, token);
    onSuccess(user, token);
  };

  const { footer, loginBtn, goSignupText, goSignupBtn } = css;

  const { t } = useTranslation()

  return (
    <Form layout="vertical" className="ant-next ant-form-large">
      <Item
        hasFeedback={isValidEmail(email)}
        validateStatus="success"
        help={
          !incorrectEmailOrPasswordError &&
          email.length > 5 &&
          !isValidEmail(email)
            ? `${t('Please enter a valid email address')}`
            : ''
        }
      >
        <Input
          key="email"
          value={email}
          size="large"
          onChange={e => setEmail(e.target.value.toLowerCase())}
          disabled={loading}
          name="email"
          autoComplete="email"
          placeholder={t("Email")}
          onPressEnter={submitForm}
        />
      </Item>
      <Item
        validateStatus={incorrectEmailOrPasswordError ? 'error' : 'success'}
        help={
          incorrectEmailOrPasswordError
            ? `${t("Wrong email or password. Please check and try again")}`
            : password.length > 0 && password.length < 8
            ? `${t('Password may be too short Please check and try again')}`
            : ''
        }
      >
        <Input
          value={password}
          size="large"
          onChange={e => setPassword(e.target.value)}
          type="password"
          disabled={loading}
          name="password"
          autoComplete="password"
          placeholder={t("Password")}
          onPressEnter={submitForm}
        />
      </Item>
      <div className={footer}>
        <span
          className={css.forgotPassword}
          onClick={switchToForgotPass}
        >
          <Text variant={isUAE?'link.black':'link.purple'}>
            {t('Forgot password?')}
          </Text>
        </span>
        <Button
          className={`ant-submit-btn ${loginBtn}`}
          disabled={!formIsValid()}
          loading={loading}
          onClick={submitForm}
        >
          {t('Login')}
        </Button>
          {!isUAE && switchToCreateStudent &&
            <div style={{ alignSelf: 'start' }}>
              <span className={goSignupText}>
                {t('Don\'t have an account?')}&nbsp;
              </span>
              <button
                className={goSignupBtn}
                onClick={() => { switchToCreateStudent() }}
              >
                {t('Sign up')}
              </button>
            </div>
          }
          {isUAE && switchToCreateStudent && switchToCreateTutor && <>
            <div style={{ alignSelf: 'start' }}>
              <span className={goSignupText}>
                {t('Don\'t have an account?')}&nbsp;
              </span>
            </div>
            <div style={{ alignSelf: 'start', margin: '0 -6px' }}>
              <button
                className={goSignupBtn}
                onClick={() => { switchToCreateStudent() }}
              >
                {t('Sign up as a student')}
              </button>
              &nbsp;{t('Or')}&nbsp;
              <button
                className={goSignupBtn}
                onClick={() => { switchToCreateTutor() }}
              >
                {t('Sign up as a tutor')}
              </button>
            </div>
            </>
          }
      </div>
    </Form>
  );
}
