import React, { useContext, useState, useRef, useEffect } from 'react';
import { Layout, Menu, Dropdown, Modal } from 'antd';
import cn from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import {
  KEY_CONTENT_ROUTES,
  AUTHENTICATED_ROUTES
} from 'routing';
import { POST } from 'utils/useApi';
import AuthContext from 'context/AuthContext';
import NotificationContext from 'context/NotificationContext';
import UaeLogo from 'static/img/uaeLogo.png';
import { logSafelyToConsole, getUnconfirmedBookings } from 'utils';
import dropDownArrow from 'static/img/dropDownArrow.svg';
import menuBurger from 'static/img/menuBurger.svg';
import { isPrerendering } from 'utils/prerender';
import { getCustomerBookings, getTutorBookings, getUnreadCustomerBookingsCount, getUnreadTutorBookingsCount } from 'api/booking';
import { Booking } from 'types';
import orderBy from 'lodash/orderBy';
import { getItem, AUTH_TOKEN } from 'utils/storage';
import LazyLoad from 'react-lazyload';

import css from './UnLoginHeader.module.scss';
import { gtm_onTutorClickOnAvailbility } from 'gtm';
import { useTranslation } from 'react-i18next';
import I18n, { lans } from 'utils/i18n';
import { saveCountry, saveLevel, saveSubject } from 'utils/useSessionStorage';
import { useHistory } from 'react-router';
import { isCN } from 'utils/env';

const { Header } = Layout;
const { SubMenu } = Menu;

const MenuDropDown = ({
  title,
  onChange,
  children,
  opened,
  count
}: {
  title: string;
  onChange: (e: boolean) => void,
  children?: JSX.Element,
  opened: boolean;
  count?: number;
}): JSX.Element => (
  <Dropdown
    onVisibleChange={onChange}
    overlay={children}
    trigger={['click']}
    overlayClassName={css.uaedropDownOverlay}
  >
    <a
      className={cn(css.dropDownLabelUae, { [css.dropDownOpened]: opened })}
      onClick={e => e.preventDefault()}
    >
      <span>
        {title}
        {count && count > 0 ? <span className={css.countBadge}>{count}</span> : null}
      </span>
      <LazyLoad>
        <img
          alt=''
          src={dropDownArrow}
          className={css.dropDownArrow}
          style={{ transform: `rotate(${opened ? 0 : 180}deg)` }}
        />
      </LazyLoad>
    </a>
  </Dropdown>
);

function UnLoginHeader({ location }: any) {
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [firstBooking, setFirstBooking] = useState<Booking | null>(null);
  const [unConfirmedBookingsCount, setUnConfirmedBookingsCount] = useState(0);
  const [unreadBookingsCount, setUnreadBookingsCount] = useState(0);

  const {
    HOME,
    TUTOR_OR_CUSTOMER_LOGIN,
    STUDENT_CREATE_ACCOUNT,
    TUTOR_CREATE_ACCOUNT
  } = KEY_CONTENT_ROUTES;
  const [menuOpened, setMenuOpened] = useState<any>({});
  const {
    TUTOR_DASHBOARD
  } = AUTHENTICATED_ROUTES;

  const auth = useContext(AuthContext);
  const notifications = useContext(NotificationContext);
  const { t, i18n } = useTranslation()
  const history = useHistory();

  const renderNavItem = (
    route: string,
    title: string,
    count: number = 0,
    onClick?: () => void,
    isSimpleLink?: boolean,
  ): JSX.Element => {
    const [pathname] = route.split("?");

    if (isSimpleLink) {
      return <li className='ant-dropdown-menu-item'>
        <a href={route}
          className={
            cn({
              [css.uaeNavItem]: true,
              [css.active]: location.pathname.startsWith(pathname),
              [css.isCN]: isCN,
            })}
        >{title}</a>
      </li>
    }
    return (
      <Menu.Item key={route} onClick={() => {
        if (onClick) onClick();
        gtm_onTutorClickOnAvailbility();
      }}>
        <Link className={
          cn({
            [css.uaeNavItem]: true,
            [css.active]: location.pathname.startsWith(pathname),
            [css.isCN]: isCN,
          })}
          target={route.startsWith("/") ? "_self" : "_blank"}
          to={route.startsWith("/") ? route : ({ pathname: route })}>
          <span>
            {title}
            {count > 0 && <span className={css.countBadge}>{count}</span>}
          </span>
        </Link>
      </Menu.Item>
    )
  };

  const resetCounters = () => {
    setUnConfirmedBookingsCount(0);
    setUnreadBookingsCount(0);
    setFirstBooking(null);
  }

  const token = getItem(AUTH_TOKEN);
  useEffect(() => {
    let interval: number | undefined;
    if (!token) {
      resetCounters();
    } else {
      getBookingsStats();

      interval = setInterval(() => {
        getBookingsStats(); // check available bookings every 2m
      }, 120000);
    }
    return () => clearInterval(interval);
  }, [token]);

  const socket = useRef<WebSocket>();

  // Create our socket only once
  useEffect(() => {
    socket.current = notifications.webSocket;
    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [notifications.webSocket]);

  const getBookingsStats = async () => {
    let bookings: Booking[] = [];
    if (!auth.user) { return; }

    if (auth.user.is_tutor) {
      const res = await getTutorBookings({ completed: false });
      bookings = res.data;
      const tutorUnreadBookingsCount = await getUnreadTutorBookingsCount();
      setUnreadBookingsCount(tutorUnreadBookingsCount.data.count);
    } else if (auth.user.is_customer) {
      const res = await getCustomerBookings({ completed: false });
      bookings = res.data;
      const customerUnreadBookingsCount = await getUnreadCustomerBookingsCount();
      setUnreadBookingsCount(customerUnreadBookingsCount.data.count);
    }

    const unConfirmedBookings = getUnconfirmedBookings(bookings, auth.user.is_customer);
    setUnConfirmedBookingsCount(unConfirmedBookings.length);

    if (bookings.length) {
      bookings = orderBy(bookings, 'start_time', 'asc');
    }
    const booking = bookings.length ? bookings[0] : null;

    setFirstBooking(booking);
  }

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const hasNotifications =
    notifications.unreadMessagesCount + notifications.unreadNotificationsCount >
    0;

  if (isPrerendering()) { return null; }

  return (
    <>
      <Header id={css.headerUae}>
        <div id={css.desktopNavUae} className={css.uaeNavHeader}>
          <div className={css.uaeLogoSection}>
            <Link to={HOME} style={{display: 'flex', alignItems: 'center'}}>
              <LazyLoad>
                <img src={UaeLogo} className={css.navLogo} alt="Tutor House Logo" />
              </LazyLoad>
            </Link>
          </div>
          <Menu className={cn(css.navMenu,!token&&css.noTokenNavMenu)} mode="horizontal">
            {renderNavItem(KEY_CONTENT_ROUTES.SEARCH_RESULTS, `${t('Find A Tutor')}`, 0, () => {
              saveSubject("0");
              saveLevel("0");
              saveCountry("0");
            })}
            {/* renderNavItem(ABOUT, `${t('About Us')}`)} */}
            {/*renderNavItem(MEMBERSHIP, 'Membership')}*/}
            {/* {renderNavItem(TEACH_WITH_US, 'Become a Tutor')} */}
            {renderNavItem(STUDENT_CREATE_ACCOUNT, `${t('Join To Learn')}`)}
            {renderNavItem(TUTOR_CREATE_ACCOUNT, `${t('Join To Teach')}`)}
            {renderNavItem('mailto:community@oktopi.ai', `${t('Contact us')}`)}
            {<div className={css.deskLogin} onClick={() => { history.push(TUTOR_OR_CUSTOMER_LOGIN); gtm_onTutorClickOnAvailbility(); }}>
            {renderNavItem(TUTOR_OR_CUSTOMER_LOGIN, `${t('Log In')}`)}
            </div>}

            {/* language */}
            {<div className={cn({[css.rightDropAuthMenu]: true, [css.language]: true, [css.isCN]: isCN})}>
              <MenuDropDown
                title={I18n.getLanLabelOfKey(i18n.language)}
                onChange={(v) => setMenuOpened({ lan: v })}
                opened={menuOpened.lan}
              >
                <Menu 
                  className={css.navSubmenu} 
                  onClick={() => setMenuOpened({ lan: false })}
                >
                  {lans.map(lan => 
                    <Menu.Item key={lan.key}>
                      <a
                        className={css.navItem}
                        onClick={() => {
                          I18n.changeLanguage(lan.key)
                        }}
                      >
                        {lan.label}
                      </a>
                    </Menu.Item>
                  )}
                </Menu>
              </MenuDropDown>
            </div>}
          </Menu>
        </div>
        <div id={css.mobileNavUae} className={css.navHeader}>
          <div className={css.uaeMobileLogo}>
            <Link to={HOME}>
              <LazyLoad>
                <img src={UaeLogo} className={css.navLogo} alt="Tutor House Logo" />
              </LazyLoad>
            </Link>
          </div>
          <Menu className={css.navMenu} mode="horizontal">
            <MenuDropDown
              title={i18n.language !== undefined ? i18n.language.toUpperCase() : '  '}
              onChange={(v) => setMenuOpened({ lan: v })}
              opened={menuOpened.lan}
            >
              <Menu 
                className={css.navSubmenu} 
                onClick={() => setMenuOpened({ lan: false })}
              >
                {lans.map(lan => 
                  <Menu.Item key={lan.key}>
                    <a
                      className={css.navItem}
                      onClick={() => {
                        I18n.changeLanguage(lan.key)
                      }}
                    >
                      {lan.label}
                    </a>
                  </Menu.Item>
                )}
              </Menu>
            </MenuDropDown>
          </Menu>
          <div className={css.navMenu} onClick={toggleMenu}>
            <LazyLoad>
              <img src={menuBurger} alt='icon' className={css.mobileIcon} />
            </LazyLoad>
          </div>
          {showMobileMenu && (
            <Menu
              mode="inline"
              className={css.mobileMenu}
              onSelect={toggleMenu}
            >
              {renderNavItem(KEY_CONTENT_ROUTES.SEARCH_RESULTS, `${t('Find a tutor')}`, 0, () => {
                saveSubject("0");
                saveLevel("0");
                saveCountry("0");
              })}
              {/* {renderNavItem(ABOUT, `${t('About Us')}`)} */}
              {/* {renderNavItem(TEACH_WITH_US, 'Become a Tutor')} */}
              {renderNavItem(STUDENT_CREATE_ACCOUNT, `${t('Join To Learn')}`)}
              {renderNavItem(TUTOR_CREATE_ACCOUNT, `${t('Join To Teach')}`)}
              {renderNavItem('mailto:community@oktopi.ai', `${t('Contact us')}`)}
              {renderNavItem(TUTOR_OR_CUSTOMER_LOGIN, `${t('Log In')}`)}

              {/* language */}
              {/* {<SubMenu className={css.navSubmenu} title={I18n.getLanLabelOfKey(i18n.language)}>
                {lans.map(lan => (
                  <Menu.Item key={lan.key}>
                    <a className={css.navItem} onClick={() => {
                      I18n.changeLanguage(lan.key)
                    }}>
                      {lan.label}
                    </a>
                  </Menu.Item>
                ))}
              </SubMenu>} */}
            </Menu>
          )}
          {hasNotifications && <span className={css.redDot} />}
        </div>
      </Header>
    </>
  );
}

export default withRouter(UnLoginHeader);
