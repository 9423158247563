import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SocialAuthBlock from 'components/SocialAuthBlock/SocialAuthBlock';
import { Box } from 'rebass';
import CreateStudentAccountForm from 'components/CreateStudentAccountForm/CreateStudentAccountForm';
import { LoginRegisterSuccessCb } from 'utils/auth';
import { isUAE } from 'utils/env';
interface Props extends RouteComponentProps {
  onSuccess: LoginRegisterSuccessCb,
  hideFooter?: boolean,
  hideTerms?: boolean,
}

function SignUpStudentBlock(props: Props) {
  const {
    history,
    hideFooter,
    onSuccess,
    hideTerms,
  } = props;
  return (
    <>
     {!isUAE&&<>
        <SocialAuthBlock onSuccess={onSuccess} />
        <Box my={3} className="th-word-divider">
          <span>
            or
          </span>
        </Box>
      </>}
      <CreateStudentAccountForm
        onCreateSuccess={onSuccess}
        history={history}
        hideFooter={hideFooter}
        hideTerms={hideTerms}
      />
    </>
  );
}

export default withRouter(SignUpStudentBlock);
