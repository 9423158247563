import { POST } from 'utils/useApi';
import React from 'react';
import { User } from 'types/Users';
import { AxiosResponse } from 'axios';

export enum CREATE_ACTIONS {
  CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR',
  SET_USER_PROP = 'SET_USER_PROP',
}

export enum CREATE_ACCOUNT_ERRORS {
  EMAIL_EXISTS_AND_INVALID_PHONE = 'EMAIL_EXISTS_AND_INVALID_PHONE',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  INVALID_PHONE = 'INVALID_PHONE_NUMBER'
}

export interface CreateAction {
  type: CREATE_ACTIONS;
  payload?: User | string | null;
}

export type CreateDispatch = React.Dispatch<CreateAction>;

export async function requestCreateTutorAccount(
  data: any,
  dispatch: CreateDispatch,
  onCreateAccountSuccess: (user: User, token: string) => void,
  onCreateAccountError: () => void
): Promise<void> {
  const {
    EMAIL_EXISTS,
    EMAIL_EXISTS_AND_INVALID_PHONE,
    INVALID_PHONE
  } = CREATE_ACCOUNT_ERRORS;

  dispatch({
    type: CREATE_ACTIONS.CREATE_ACCOUNT_REQUEST,
    payload: null
  });

  try {
    const useToken = false;
    const result = (await POST(
      'v3/customer/register/',
      data,
      useToken
    )) as AxiosResponse;
    const { user, token } = result.data;

    dispatch({
      type: CREATE_ACTIONS.CREATE_ACCOUNT_SUCCESS,
    });

    onCreateAccountSuccess(user, token);
  } catch (err) {
    const action = { type: CREATE_ACTIONS.CREATE_ACCOUNT_ERROR };

    if (err.response && err.response.status === 400) {
      const data = err.response.data;

      if ('email' in data && 'phone_number' in data) {
        return dispatch(
          { ...action, payload: EMAIL_EXISTS_AND_INVALID_PHONE }
        );
      }

      if ('email' in data) {
        return dispatch(Object.assign(action, { payload: EMAIL_EXISTS }));
      }

      if ('phone_number' in data) {
        return dispatch(Object.assign(action, { payload: INVALID_PHONE }));
      }

      onCreateAccountError();
      return dispatch(Object.assign(action, { payload: null }));
    } else {
      dispatch(Object.assign(action, { payload: null }));
      onCreateAccountError();
    }
  }
}

