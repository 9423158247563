import React from 'react';
import { hydrate, render } from "react-dom";
import ResizeObserver from 'resize-observer-polyfill';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Google Identity Services
// https://github.com/MomenSherif/react-oauth
import { GoogleOAuthProvider } from '@react-oauth/google';

import { ConfigProvider } from 'antd';
import arEG from 'antd/es/locale/ar_EG';
import enGB from 'antd/es/locale/en_GB';
import i18n from 'utils/i18n';

import 'antd/dist/antd.css';
import './App.scss';
import './FontFamily.scss';
import './i18n';

(window as any).ResizeObserver = ResizeObserver; // for safari

const { REACT_APP_AUTH_GOOGLE_OAUTH2_KEY } = process.env
const useGoogleOAuthApp = <GoogleOAuthProvider 
  clientId={REACT_APP_AUTH_GOOGLE_OAUTH2_KEY as string}
>
  <ConfigProvider locale={i18n.isAr ? arEG : enGB}>
    <App />
  </ConfigProvider>
</GoogleOAuthProvider>

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  // hydrate(<App />, rootElement);
  hydrate(useGoogleOAuthApp, rootElement);
} else {
  // render(<App />, rootElement);
  render(useGoogleOAuthApp, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
